<template>
  <i v-if="parsedIcon" class="icon">
    <svg viewBox="0 0 24 24" class="icon-svg">
      <path v-bind="parsedIcon" />
    </svg>
  </i>
</template>

<script lang="ts" setup>
const props = defineProps({
  icon: { type: String, required: true }
})

const parsedIcon = computed(() => useIcons()[props.icon])
</script>

<style lang="scss">
.icon {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  flex: 1 0 1em;
  fill: currentColor;

  &-svg {
    width: 100%;
    height: 100%;
    fill: currentColor;
  }
}
</style>
